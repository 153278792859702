export const required = value => {
  if (Array.isArray(value)) {
    if (value.length > 0) {
      return undefined;
    }
    return 'This field is required';
  }

  return value ? undefined : 'This field is required';
};

export const maxLength = max => value =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined;
export const minLength = min => value =>
  value && value.length < min ? `Must be ${min} characters or more` : undefined;
export const number = value =>
  value && Number.isNaN(Number(value)) ? 'Must be a number' : undefined;
export const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Enter a valid email address.'
    : undefined;

export function validateAddress(values) {
  const errors = {};
  if (required(values.addr_first_line)) {
    errors.addr_first_line = required(values.addr_first_line);
  }

  if (required(values.addr_city)) {
    errors.addr_city = required(values.addr_city);
  }

  if (required(values.addr_country)) {
    errors.addr_country = required(values.addr_country);
  }

  return errors;
}
