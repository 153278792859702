import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { PERSONAL_DETAILS_STEP, PAYMENT_DETAILS_STEP } from './constants';

const ProgressBar = ({ currentStep }) => {
  return (
      <div className="f-reg-steps">
        <ul>
          <li className={classNames("f-reg-step", {"f-reg-step--active": currentStep === PERSONAL_DETAILS_STEP})}>
            <span className={classNames("f-reg-step__bar")} />
            Personal details
          </li>
          <li className={classNames("f-reg-step", {"f-reg-step--active": currentStep === PAYMENT_DETAILS_STEP})}>
            <span className={classNames("f-reg-step__bar")} />
            Request Payment
          </li>
        </ul>
      </div>
  )
}

ProgressBar.propTypes = {
  currentStep: PropTypes.string.isRequired,
};
ProgressBar.defaultProps = {};

export default ProgressBar;
